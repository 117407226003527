import React from 'react';
import styled from 'styled-components';
import theme from '../styles/theme';
import Link from 'next/link';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { faCalendarAlt, faListAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faSignOutAlt,
  faTachometerAlt,
  faPlus,
  faEdit,
  faBookReader,
  faGlobeAmericas,
  faMicrophone,
  faHourglassHalf,
  faThumbtack,
  faUser,
  faIdCard,
  faPaperPlane,
  faSearch,
  faVideo,
  faFile,
  faList
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { useRouter } from 'next/router';
import Image from 'next/image';
import Client from '../../domain/models/client';

const MenuContentContainer = styled.div``;
const MenuBarContainer = styled.div`
  background-color: #343c49;
  padding-top: 0.75rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    color: #c8cfdc;
    font-size: 23px;
    font-weight: 300;
  }
`;
const LogoContainer = styled.div`
  text-align: center;
`;
const MenuItemsContainer = styled.div`
  padding: 1rem 0.8rem;
`;
const MenuItemContainer = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #c8cfdc;
  display: flex;
  align-items: flex-end;
  a {
    font-size: 14px;
    font-weight: 300;
    :hover {
      color: ${theme.colors.primary};
      opacity: 0.6;
    }
  }
`;
const Icon = styled.div`
  width: 1.5rem;
  text-align: center;
  margin-right: 0.5rem;
`;
const MenuFooterContainer = styled.div`
  background-color: #222933;
  height: 60px;
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  div {
    color: #c8cfdc;
    font-size: 13px;
    font-weight: 400;
  }
`;
const SignOutButton = styled.button`
  background: none;
  border: none;
  color: #c8cfdc;
  cursor: pointer;
  :focus {
    outline: 0;
  }
  span {
    margin-left: 0.5rem;
  }
`;
const Spacer = styled.div`
  width: 10px;
  height: 1rem;
`;

type PropsType = {
  session: any;
  draftProjectsCount: number;
  castingProjectsCount: number;
  pendingProjectsCount: number;
  openProjectsCount: number;
  submittedProjectsCount: number;
  projectRolesWithPendingSubmissionsCount: number;
  client?: Client;
};

function Menu(props: PropsType) {
  const {
    session,
    draftProjectsCount,
    castingProjectsCount,
    pendingProjectsCount,
    openProjectsCount,
    submittedProjectsCount,
    projectRolesWithPendingSubmissionsCount,
    client
  } = props;
  const router = useRouter();

  const logout = () => {
    confirmAlert({
      title: 'Sign Out?',
      // message: "Are you sure to do this.",
      buttons: [
        {
          label: 'Yes',
          onClick: () => (window.location.href = '/logout')
        },
        {
          label: 'No',
          onClick: () => {
            console.log('No was tapped');
          }
        }
      ]
    });
  };

  const styleForActiveLink = (path: string) => {
    return router.asPath == path ? { color: theme.colors.primary, opacity: 0.85 } : {};
  };

  const renderMenuItem = (
    icon: IconDefinition,
    href: string,
    title: string,
    count?: number,
    newWindow?: boolean,
    iconColor?: string
  ) => {
    return (
      <MenuItemContainer>
        <Icon>
          <Link href={href}>
            <a target={newWindow ? '_blank' : ''}>
              <FontAwesomeIcon icon={icon} color={iconColor} />
            </a>
          </Link>
        </Icon>
        <Link href={href}>
          <a style={styleForActiveLink(href)} target={newWindow ? '_blank' : ''}>
            {title} {count > 0 && <span>({count})</span>}
          </a>
        </Link>
      </MenuItemContainer>
    );
  };

  const renderClientMenuItems = () => {
    return (
      <>
        {renderMenuItem(faBookReader, '/', 'Open Roles')}
        {client &&
          renderMenuItem(
            faMicrophone,
            `/clients/previous-submissions/${client.unique}`,
            'Previous Submissions'
          )}
        {client &&
          renderMenuItem(
            faFile,
            `/clients/docs/${client.unique}`,
            'Manage Documents',
            client.documents_valid ? 0 : 1,
            false,
            client.documents_valid ? null : theme.colors.warning
          )}
        {client && renderMenuItem(faEdit, `/clients/bio/${client.unique}`, 'Manage Bio', 0, true)}
        {client &&
          renderMenuItem(
            faCalendarAlt,
            `/clients/unavailable/${client.unique}`,
            'Unavailable Dates',
            0,
            true
          )}
        {client &&
          renderMenuItem(
            faUser,
            `https://voxusa.net/clients/${client.slug}`,
            'View Bio Page',
            0,
            true
          )}
      </>
    );
  };

  const renderAuthorizedMenuItems = () => {
    return (
      <>
        {renderMenuItem(faTachometerAlt, '/', 'Dashboard')}
        {renderMenuItem(faSearch, '/search', 'Search')}
        {renderMenuItem(
          faMicrophone,
          '/pending-submissions',
          'Pending Submissions',
          projectRolesWithPendingSubmissionsCount
        )}
        {renderMenuItem(faBookReader, '/open-projects', 'Open Projects', openProjectsCount)}
        <Spacer />
        {renderMenuItem(faPlus, '/new-project', 'New Project')}
        {renderMenuItem(faEdit, '/draft-projects', 'Draft Projects', draftProjectsCount)}
        {renderMenuItem(faEdit, '/casting-projects', 'Casting Projects', castingProjectsCount)}
        {renderMenuItem(faThumbtack, '/pending-projects', 'Pending Projects', pendingProjectsCount)}
        {renderMenuItem(faPaperPlane, '/submitted-projects', 'Submitted Projects')}
        {/* all projects fetch size has become too large */}
        {/* {renderMenuItem(faGlobeAmericas, '/projects', 'All Projects')} */}
        {renderMenuItem(faUser, '/clients', 'Clients')}
        {renderMenuItem(faIdCard, '/buyers', 'Buyers')}
        <Spacer />
        {renderMenuItem(faList, '/properties', 'Property Lists')}
      </>
    );
  };

  const renderMenuItems = () => {
    if (session?.user?.role == 'client') {
      return renderClientMenuItems();
    }
    return renderAuthorizedMenuItems();
  };

  return (
    <>
      <MenuContentContainer>
        <MenuBarContainer>
          <LogoContainer>
            <Link href="/">
              <a>
                <Image src="/vox_logo.jpg" alt="Picture of the author" width={60} height={55} />
              </a>
            </Link>
          </LogoContainer>
        </MenuBarContainer>
        <MenuItemsContainer>{renderMenuItems()}</MenuItemsContainer>
      </MenuContentContainer>
      <MenuFooterContainer>
        <div>
          <SignOutButton onClick={logout}>
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span>{session?.user?.name}</span>
          </SignOutButton>
        </div>
      </MenuFooterContainer>
    </>
  );
}

export default Menu;
